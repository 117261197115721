import { GraphQLClient } from 'graphql-request';
import * as Sentry from '@sentry/nextjs';
import { pluck } from 'utils';
import config from 'brandConfig';

const handle = pluck(config, 'contexts.0.shopifyHandle');
const token = pluck(config, 'contexts.0.shopifyStorefrontToken');
const endpoint = `https://${handle}.myshopify.com/api/2023-01/graphql.json`;

const client = new GraphQLClient(endpoint, {
  headers: {
    'X-Shopify-Storefront-Access-Token': token,
    'Content-Type': 'application/json',
  },
});

const shopify = (query) =>
  client
    .request(query)
    .then((response) => {
      return response;
    })
    .catch((e) => {
      Sentry.captureException(e);
      console.log('Shopify Fetch Exception:', e);
      return null;
    });

export default shopify;
