import ShopifyFeaturedProducts from '@components/Product/ShopifyFeaturedProducts/ShopifyFeaturedProducts';

const CollectionGrid = ({ slice }) => {
  const { title, columns, shopifyCollectionHandle } = slice.primary;

  return (
    <ShopifyFeaturedProducts
      title={title}
      columns={columns || 4}
      shopifyCollectionHandle={shopifyCollectionHandle}
    />
  );
};

export default CollectionGrid;
