import { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import Slider from 'react-slick';
import styles from './CollectionFeaturedProducts.module.css';
import { getShopifyClientSettings } from '@lib/shopify/get-client-settings';
import { collectionByHandle } from 'lib/shopify';
import { ShopifyClientContext } from '@lib/shopify/client';
import getClient from '@lib/shopify/shopify-gql-client-setting/ShopifyGQL';
import DefaultCollectionFilter from '@lib/shopify/filters/default-collection-filter';

import ShopifyProductCard from '@components/ShopifyProductCard';

interface Props {
  title: string;
  columns: number;
  shopifyCollectionHandle: string;
}

const CollectionFeaturedProducts: FC<Props> = (props: any) => {
  const { title, columns, shopifyCollectionHandle } = props;
  const [featuredProducts, setFeaturedProducts] = useState(null);

  const clientSettings = getShopifyClientSettings('AU');

  const client = (context: ShopifyClientContext) => {
    return getClient(context.shopifyDomain, context.storefrontAccessToken);
  };

  const getCollectionProductsByHandle = async () => {
    const shopify = client(clientSettings);

    const variables = {
      handle: shopifyCollectionHandle,
      filters: DefaultCollectionFilter,
      first: 12,
      sortKey: 'COLLECTION_DEFAULT',
    };
    try {
      const collectionResponse = await shopify(collectionByHandle, variables);

      setFeaturedProducts(collectionResponse.collection.products.edges);
    } catch (error) {
      console.log(`Could not re-fetch collection for "${shopifyCollectionHandle}"`, error);
      return null;
    }
  };

  const theNumberOfColumns: number = Number(columns);

  const sliderSettings = {
    className: cn({ 'hide-slider': featuredProducts?.length < theNumberOfColumns }),
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: theNumberOfColumns,
    slidesToScroll: theNumberOfColumns,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: cn({ 'hide-slider': featuredProducts?.length < 4 }),
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          className: cn({ 'hide-slider': featuredProducts?.length < 3 }),
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 425,
        settings: {
          className: cn({ 'hide-slider': featuredProducts?.length < 2 }),
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  useEffect(() => {
    getCollectionProductsByHandle();
  }, []);

  if (!featuredProducts) {
    return null;
  }

  return (
    <div
      className={cn(`${styles.wrapper} product-slick`, {
        hidden: featuredProducts.length === 0,
      })}
    >
      <div className="uppercase text-text-dark font-semibold text-20 leading-6 tablet:text-h4">
        {title}
      </div>
      <div className="mt-8">
        <Slider {...sliderSettings}>
          {featuredProducts &&
            featuredProducts.map((item: any, index: number) => {
              return (
                <div key={index} className="collection-featured-products">
                  <ShopifyProductCard product={item.node} />
                </div>
              );
            })}
        </Slider>
      </div>

      <style jsx global>
        {`
          .collection-featured-products .product-card {
            min-height: 330px !important;
          }
        `}
      </style>
    </div>
  );
};

export default CollectionFeaturedProducts;
