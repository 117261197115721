import PropTypes from 'prop-types';
import { pluck } from 'utils';

const Badges = ({ product, multibuyOffer }) => {
  const { compareAtPrice, price, meta, tags } = product;
  const sp = pluck(meta, 'supermarket.price') || 0;
  const supermarketPrice = Number.isNaN(sp) ? 0 : parseFloat(sp);

  const badgeTags = ['clearance', 'new-arrival', 'coming-soon', 'hot-buy'];

  let badges = badgeTags.map((tag) => tags?.find((tagItem) => tagItem === tag));
  badges = badges.filter((aaa) => aaa !== undefined);

  const multibuyOfferLength = multibuyOffer?.length || 0;

  const badgesInfo = {
    clearance: {
      textColour: '#ffed00',
      bgColour: '#cc0117',
      maxWidth: '80px',
      text: 'While stock last',
    },
    'new-arrival': {
      textColour: '#ffffff',
      bgColour: '#272727',
      maxWidth: '35px',
      text: 'New',
    },
    'coming-soon': {
      textColour: '#ffffff',
      bgColour: '#414141',
      maxWidth: '70px',
      text: 'Coming soon',
    },
    'hot-buy': {
      textColour: '#000000',
      bgColour: '#ffed00',
      maxWidth: '40px',
      text: 'Hot',
    },
    sale: {
      textColour: '#ffffff',
      bgColour: '#cc0117',
      maxWidth: '35px',
      text: 'Sale',
    },
    'multi-buy': {
      textColour: '#ffffff',
      bgColour: '#cc0117',
      maxWidth: multibuyOfferLength < 9 ? '56px' : '65px',
      text: multibuyOffer,
      textTransform: 'upperCase',
    },
  };

  const hasDiscount = () => {
    return (
      Number(compareAtPrice) > Number(price) ||
      (supermarketPrice && supermarketPrice > Number(price))
    );
  };

  if (multibuyOffer) badges.push('multi-buy');
  if (hasDiscount()) badges.push('sale');

  if (badges.length) {
    return (
      <div className="product-card-badge-container">
        {badges.map((badge) => {
          return (
            <span
              key={badge}
              className="product-card-badge"
              style={{
                color: badgesInfo[badge].textColour,
                backgroundColor: badgesInfo[badge].bgColour,
                maxWidth: badgesInfo[badge].maxWidth,
                textTransform: badgesInfo[badge].textTransform,
              }}
            >
              {badgesInfo[badge].text}
            </span>
          );
        })}
      </div>
    );
  }

  return null;
};

Badges.propTypes = {
  product: PropTypes.object.isRequired,
  multibuyOffer: PropTypes.string,
};

Badges.defaultProps = {
  multibuyOffer: null,
};

export default Badges;
