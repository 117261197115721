import React from 'react';
import CollectionFeaturedProducts from '../CollectionFeaturedProducts/CollectionFeaturedProducts';

interface props {
  title: string;
  columns: number;
  shopifyCollectionHandle: string;
}

const ShopifyFeaturedProducts = ({ title, columns, shopifyCollectionHandle }: props) => {
  return (
    <div className="pt-10">
      <CollectionFeaturedProducts
        title={title}
        columns={columns}
        shopifyCollectionHandle={shopifyCollectionHandle}
      />
    </div>
  );
};

export default ShopifyFeaturedProducts;
